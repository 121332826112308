import React from 'react';

import './style.scss';
import Helmet from './helmet';
import Header from './header';
import Midsection from './midsection';
import Footer from './footer';
import Hero from './hero';

const Layout = ({ children }) => (
  <div>
    <Helmet />
    <Header />
    <Hero />
    {/* <Midsection /> */}
    <Footer />
  </div>
);

export default Layout;
